.x13faq {
  &__block-faq_category {
    .title_block:after {
      display: none;
    }
  }
  &__menu-right {
    .block_content {
      transition: .15s ease;
    }
  }
  &__menu-right,
  &__left-column {
    @media (max-width: 767px) {
      margin-bottom: 20px !important;
    }
     ul  {
        li {
          a {
            font-size: 15px;
          }
          &.active {
            a {
              color: #333;
          } 
        }
      }
    }
  }
  &__search {
    &-input {
      font-size: 14px;
    }
    &-btn {
      font-size: 15px;
    }
  }
  &__other-heading {
    color: #222222;
  }
}